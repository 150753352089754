import React from 'react';
import moment from 'moment';
import 'moment-duration-format';

function Timeago({ timestamp }) {
  return (
    <span>{moment(timestamp).calendar()}</span>
  );
}

export default Timeago;
