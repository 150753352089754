import React from 'react';
import gql from 'graphql-tag';
import { Query } from "react-apollo";

import ClickOutside from '../clickOutside';
import HeaderSearchResults from './search/results';
import styles from './search.module.scss';

const GET_SEARCH_RESULTS = gql`
  query GetSearchResuts($query: String!) {
    search(query: $query) {
      __typename
      ... on Space {
        id
        name
        key
        client {
          id
          name
        }
      }
      ... on Todo {
        id
        name
        number
        done
      }
    }
  }
`;

class HeaderSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      showResults: false
    }

    this.handleSearch = this.handleSearch.bind(this);
    this.showResults = this.showResults.bind(this);
    this.hideResults = this.hideResults.bind(this);
  }

  handleSearch(e) {
    const that = this;
    const value = e.target.value;

    clearTimeout(window.searchTimeout);

    window.searchTimeout = setTimeout(function(){
      that.setState({ query: value });
    }, 300);
  }

  showResults() {
    this.setState({ showResults: true });
  }

  hideResults() {
    const that = this;

    setTimeout(function(){
      that.setState({ showResults: false });
    }, 200);
  }

  render() {
    const { query, showResults } = this.state;

    return (
      <div className={styles.wrapper}>
        <ClickOutside onClick={this.hideResults}>
          <div className={styles.search} data-show-results={showResults}>
            <input
              ref={(ref) => this.input = ref }
              className={styles.input}
              placeholder="Search..."
              onChange={this.handleSearch}
              onFocus={this.showResults}
              data-has-query={query.length !== 0}
            />
            <div className={styles.results}>
              <Query
                query={GET_SEARCH_RESULTS}
                variables={{ query }}
                onCompleted={data => this.setState({ resultCount: data.search.length, activeIndex: 0 })}
              >
                {({ loading, error, data }) => {
                  if (query.length === 0) {
                    return null;
                  }

                  if (loading) return "Loading...";
                  if (error) return `Error! ${error.message}`;

                  if (!loading && data && data.search.length === 0) {
                    return <div className={styles.empty}>Could not find any results. Please update your query.</div>;
                  }

                  if (!loading && data && data.search.length > 0) {
                    return <HeaderSearchResults results={data.search}/>;
                  }
                }}
              </Query>
            </div>
          </div>
        </ClickOutside>
      </div>
    );
  }
}

export default HeaderSearch;
