import React from 'react';

import HeaderSearchResult from './result';

class HeaderSearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0
    }

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setActiveIndex = this.setActiveIndex.bind(this);
  }

  setActiveIndex(newIndex) {
    const { results } = this.props;
    const { activeIndex } = this.state;
    const resultCount = results.length;

    if (newIndex === resultCount || newIndex === -1) {
      return false;
    }

    if (activeIndex !== newIndex) {
      this.setState({ activeIndex: newIndex });
    }
  }

  handleKeyPress(e) {
    const key = e.key;
    const { activeIndex } = this.state;

    if (key === 'ArrowUp') {
      this.setActiveIndex(activeIndex - 1);
    }

    if (key === 'ArrowDown') {
      this.setActiveIndex(activeIndex + 1);
    }

    if (key === 'Enter') {
      this.results.childNodes[activeIndex].click();
    }
  }

  componentWillMount() {
    window.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  render() {
    const { results } = this.props;
    const { activeIndex } = this.state;

    const items = results.map((result, index) => <HeaderSearchResult key={index} index={index} result={result} selected={index === activeIndex} setActiveIndex={this.setActiveIndex}/>);

    return (
      <div ref={(ref) => this.results = ref } data-wrapper>
        {items}
      </div>
    );
  }
}

export default HeaderSearchResults;
