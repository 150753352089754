import React from 'react';
import Helmet from 'react-helmet';

function Title({ title }) {
  return (
    <Helmet>
      <title>{title} &mdash; WORKPLACE</title>
    </Helmet>
  );
}

export default Title;
