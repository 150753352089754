import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Modal from '../../components/modal';
import ProgressBar from '../progress/bar';
import SpaceForm from '../../components/space/form';
import { pathTo } from '../../routes';
import Timeago from '../time/ago';
import styles from './gridItem.module.scss';

function SpacesGridItem({ space }) {
  const [showModal, toggleShowModal] = useState(false);

  return (
    <div className={styles.space}>
      {( showModal &&
        <Modal name={`You’re about to edit "${space.name}"`} onClose={event => toggleShowModal(false)}>
          <SpaceForm space={space} onSuccess={event => toggleShowModal(false)}/>
        </Modal>
      )}
      <NavLink className={styles.anchor} to={pathTo(space)}>
        {( space.name &&
          <div className={styles.name}>{space.name}</div>
        )}
        {( (space.client && space.client.name) &&
          <div className={styles.clientName}>{space.key} - {space.client.name}</div>
        )}
        <div className={styles.updated}>
          <Timeago timestamp={space.updatedAt}/>
        </div>
        <div className={styles.progress}>
          <ProgressBar progressInPercent={space.progressInPercent}/>
        </div>
        <div className={styles.status}>
          {(space.archivedAt !== null &&
            <div className={styles.action}>
              <i className="fas fa-archive"/>
            </div>
          )}
          <div className={styles.action} data-hover='true' onClick={event => {
              event.preventDefault();
              toggleShowModal(true);
            }}>
            <i className="fas fa-ellipsis-h"/>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default SpacesGridItem;
