import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';

import Button from '../../components/button';

const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($id: ID!, $name: String, $spaceId: Int, $clientId: Int, $state: String) {
    updateInvoice(id: $id, name: $name, spaceId: $spaceId, clientId: $clientId, state: $state) {
      id
      number
      name
      state
      createdAt
      client {
        id
        name
      }
      space {
        id
        name
      }
    }
  }
`;

function ActionButtons({ invoiceId, status, client }) {
  const [updateState] = useMutation(UPDATE_INVOICE, {
    update(cache, { data: { updateInvoice } }) {
      client.resetStore();
    }
  });

  if (status === 'draft') {
    return <Button
              label='Mark as pending'
              variant='secondary'
              size='small'
              onClick={event => {
                updateState({
                  variables: {
                    id: invoiceId,
                    state: 'pending'
                  }
                })
              }}
            />;
  }

  if (status === 'pending') {
    return (
      <>
        <Button
          label='Mark as draft'
          variant='secondary'
          size='small'
          onClick={event => {
            updateState({
              variables: {
                id: invoiceId,
                state: 'draft'
              }
            })
          }}
        />
        <Button
          label='Mark as paid'
          variant='secondary'
          size='small'
          onClick={event => {
            updateState({
              variables: {
                id: invoiceId,
                state: 'paid'
              }
            })
          }}
        />
      </>
  );
  }

  if (status === 'paid') {
    return <Button
              label='Mark as pending'
              variant='secondary'
              size='small'
              onClick={event => {
                updateState({
                  variables: {
                    id: invoiceId,
                    state: 'pending'
                  }
                })
              }}
            />;
  }
}

export default withApollo(ActionButtons);
