import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';
import moment from 'moment';

import Button from '../button';
import { isForToday, isOn, isWithinThisWeek, endOfWeek } from './index';
import Popup from '../popup';
import styles from './selector.module.scss';

const UPDATE_TODO = gql`
  mutation UpdateTodo($id: ID!, $dueAt: ISO8601DateTime) {
    updateTodo(id: $id, dueAt: $dueAt) {
      id
      name
      dueAt
    }
  }
`;

function DateSelector({ todo, client, size='small' }) {
  const [showPopup, setShowPopup] = useState(false);
  const [setDueAt] = useMutation(UPDATE_TODO, {
    update(cache, { data: { updateTodo } }) {
      client.resetStore();
      setShowPopup(false);
    }
  });

  // icon
  let icon = 'far fa-calendar-check';
  let color = 'later';

  if (isWithinThisWeek(todo.dueAt)) {
    icon = 'far fa-moon';
    color = 'week';
  }

  if (isForToday(todo.dueAt)) {
    icon = 'fas fa-star';
    color = 'today';
  }

  if (todo.dueAt === null) {
    icon = 'far fa-calendar-alt';
  }

  const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
    const newDate = moment().add(day, 'days');
    return {
      name: newDate.format('ddd'),
      date: newDate.format('DD'),
      isoDate: newDate.toISOString()
    }
  });

  const dayButtons = days.map(({ date, name, isoDate }, index) => {
    const isCurrent = isOn(todo.dueAt, isoDate);

    return (
      <div
        key={index}
        className={styles.action}
        onClick={ event => {
          setDueAt({
            variables: {
              id: todo.id,
              dueAt: isoDate
            }
          })
        }}
        data-current={isCurrent}
      >
        <div className={styles.name}>
          {name}
        </div>
        <div className={styles.date}>
          {date}
        </div>
      </div>
    )
  })

  return (
    <Popup show={showPopup} togglePopup={setShowPopup}>
      <Button size={size} variant="link" onClick={() => setShowPopup(true)}>
        <i className={icon} data-color={color}/>
      </Button>
      <>
        <div className={styles.section}>
          <div
            className={styles.action}
            data-scope="today"
            onClick={ event => {
              const now = new Date();
              setDueAt({
                variables: {
                  id: todo.id,
                  dueAt: now.toISOString()
                }
              })
            }}
          >
            <i className="fas fa-star"/><span>Today</span>
          </div>
        </div>
        <div className={styles.section}>
          <div
            className={styles.action}
            data-scope="week"
            onClick={ event => {
              setDueAt({
                variables: {
                  id: todo.id,
                  dueAt: endOfWeek()
                }
              })
            }}
          >
            <i className="far fa-moon"/><span>This week</span>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.days}>
            {dayButtons}
          </div>
        </div>
        <div className={styles.section}>
          <div
            className={styles.action}
            onClick={ event => {
              setDueAt({
                variables: {
                  id: todo.id,
                  dueAt: null
                }
              })
            }}
          >
            <i className="far fa-calendar-times"/><span>Later</span>
          </div>
        </div>
      </>
    </Popup>
  )
}

export default withApollo(DateSelector);
