import React from 'react';

import UserAvatar from '../avatar';

import styles from './header.module.scss';

function UserProfileHeader({ user }) {
  return (
    <div className={styles.header}>
      <div className={styles.avatar}>
        <UserAvatar user={user}/>
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {user.firstname} {user.lastname}
        </div>
        <div className={styles.position}>
          Company / Position
        </div>
      </div>
    </div>
  );
}

export default UserProfileHeader;
