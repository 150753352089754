import React, { useState } from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Button from '../button';
import ClickOutside from '../clickOutside';
import styles from './formSmall.module.scss';

const ADD_TODO = gql`
  mutation AddTodo($listId: Int!, $name: String!, $assigneeId: Int) {
    addTodo(listId: $listId, name: $name, assigneeId: $assigneeId) {
      id
      name
      number
      done
    }
  }
`;

function TodoFormSmall({ assignees, listId, client }) {
  const [name, setName] = useState('');
  const [focused, setFocused] = useState(false);
  const [assigneeId, setAssigneeId] = useState(1);
  const [error, setError] = useState(null);

  const users = [{ id: null, firstname: 'No', lastname: 'Body'}, ...assignees].map(user => <option key={user.id} value={user.id}>{user.firstname} {user.lastname[0]}.</option>);

  return (
    <Mutation
      key={listId}
      mutation={ADD_TODO}
      variables={{ listId, name, assigneeId }}
      update={(store, { data: { addTodo } }) => {
        client.resetStore();
        setError(null);
      }}
      onCompleted={() => {
        setName('');
        setFocused(false);
      }}
      onError={(graphQLErrors, networkError) => {
        const split = graphQLErrors.toString().split(": ");
        const message = split[split.length - 1];

        setError(message);
      }}
    >
      {(mutation, loading) => {
        return (
          <ClickOutside onClick={event => setFocused(false)}>
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
              }
            }>
              <div className={styles.top}>
                <div className={styles.icon}>
                  <i className="fas fa-plus"/>
                </div>
                <div className={styles.name}>
                  <input
                    className={styles.input}
                    data-focused={focused}
                    value={name}
                    onChange={event => setName(event.target.value)}
                    onFocus={event => setFocused(true)}
                    placeholder='Describe this to-do…'
                  />
                </div>
                {( focused &&
                  <>
                  <div className={styles.assignee}>
                    <select
                      className={styles.select}
                      value={assigneeId}
                      onChange={event => setAssigneeId(parseInt(event.target.value, 10))}
                      onFocus={event => setFocused(true)}
                    >
                      {users}
                    </select>
                  </div>
                  <Button
                    label='Save'
                    onClick={event => {
                      mutation();
                    }}
                    size='small'
                  />
                  </>
                )}
              </div>
              <div className={styles.bottom}>
                {( error !== null &&
                  <div className={styles.error}>{error}</div>
                )}
              </div>
            </form>
          </ClickOutside>
        )
      }}
    </Mutation>
  );
}

export default withApollo(TodoFormSmall);
