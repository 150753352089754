export function pathTo(resource) {
  switch (resource.__typename) {
    case 'Invoice':
      return `/invoices/${resource.id}`;
    case 'Space':
      return `/spaces/${resource.id}`;
    case 'Todo':
      return `/todos/${resource.id}`;
    case 'User':
      return `/users/${resource.username}`;
    default:
      return `/`;
  }
}
