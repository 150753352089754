import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Button from '../../components/button';
import ButtonBar from '../../components/button/bar';
import InvoicesListing from '../../components/invoices/listing';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import InvoiceForm from '../../components/invoice/form';
import Layout from '../../components/layout';
import Title from '../../components/title';

const GET_INVOICES = gql`
  {
    invoices {
      id
      number
      name
      state
      createdAt
      client {
        id
        name
      }
      space {
        id
        name
      }
    }
  }
`;

function Invoices() {
  const [showModal, toggleShowModal] = useState(false);

  const { loading, error, data } = useQuery(GET_INVOICES);

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );
  
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Title title='Invoices'/>
      <Layout>
        {( showModal &&
          <Modal name='All right, let’s add a new invoice!' onClose={event => toggleShowModal(false)}>
            <InvoiceForm onSuccess={event => toggleShowModal(false)}/>
          </Modal>
        )}
        <ButtonBar>
          <Button label='Add Invoice' onClick={event => toggleShowModal(true)}/>
        </ButtonBar>
        <InvoicesListing invoices={data.invoices}/>
      </Layout>
    </>
  );
}

export default Invoices;
