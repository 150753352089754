import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Loading from '../../components/loading';
import Layout from '../../components/layout';
import Title from '../../components/title';
import UserProfileHeader from '../../components/user/profile/header';
import UserWorkloadCalendar from '../../components/user/workload/calendar';

const GET_USER = gql`
  query GetUser($username: String!){
    me {
      id
      firstname
      lastname
    }

    user(username: $username) {
      firstname
      lastname
      username
    }
  }
`;

function UserProfile({ match }) {
  const username = match.params.username;

  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      username
    }
  });

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  const { user } = data;

  if (user === null) {
    return (
      <Layout>
        User not found.
      </Layout>
    )
  }

  return (
    <>
      <Title title={`${user.firstname} ${user.lastname}`}/>
      <Layout>
        <UserProfileHeader user={user}/>
        <UserWorkloadCalendar user={user}/>
      </Layout>
    </>
  );
}

export default UserProfile;
