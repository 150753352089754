import React from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';

import ListsListingHeader from './header';
import TodoFormSmall from '../todo/formSmall';
import TodosListing from '../todos/listing';
import styles from './listing.module.scss';

const UPDATE_TODO = gql`
  mutation UpdateTodo($id: ID!, $listId: Int, $position: Int!) {
    updateTodo(id: $id, listId: $listId, position: $position) {
      id
      name
      position
      list {
        id
      }
    }
  }
`;

function onDragEnd(result, updateTodo) {
  const todoId = parseInt(result.draggableId, 10);
  const position = result.destination.index + 1;
  const listId = parseInt(result.destination.droppableId.split('-')[1], 10);

  updateTodo({
    variables: {
      id: todoId,
      listId: listId,
      position: position
    }
  })
}

function ListsListing({ space, client }) {
  const [updateTodo] = useMutation(UPDATE_TODO, {
    update(cache, { data: { updateTodo } }) {
      client.resetStore();
    }
  });

  const items = space.lists.map((list, index) => {
    const openTodos = list.todos.filter(todo => !todo.done);
    const doneTodos = list.todos.filter(todo => todo.done);

    return (
      <div key={index} className={styles.list}>
        <ListsListingHeader list={list} doneCount={doneTodos.length} totalCount={list.todos.length}/>
        <div className={styles.open}>
          <TodosListing todos={openTodos} listId={list.id} sortable={true}/>
        </div>
        <div className={styles.form}>
          <TodoFormSmall listId={parseInt(list.id, 10)} assignees={space.users}/>
        </div>
        <div className={styles.done}>
          <TodosListing todos={doneTodos} limit={5}/>
        </div>
      </div>
    )
  });

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result, updateTodo)}>
      <div className={styles.lists}>
        {items}
      </div>
    </DragDropContext>
  );
}

export default withApollo(ListsListing);
