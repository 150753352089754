import React from 'react';
import { NavLink } from 'react-router-dom';

import Container from '../container';
import HeaderLogout from './logout';
import HeaderSearch from './search';
import { pathTo } from '../../routes';
import UserAvatar from '../user/avatar';

import styles from './index.module.scss';

function Header({ me }) {
  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.id}>
            <NavLink to='/'><i className="fas fa-globe"/> <span>WORKPLACE</span></NavLink>
          </div>
          <div className={styles.nav}>
            <NavLink className={styles.item} activeClassName={styles.active} to='/spaces'>
              <i className="fas fa-box-open"/> <span>Spaces</span>
            </NavLink>
            <NavLink className={styles.item} activeClassName={styles.active} to='/invoices'>
              <i className="fas fa-file-invoice-dollar"/> <span>Invoices</span>
            </NavLink>
          </div>
          <HeaderSearch/>
          {( me &&
            <>
              <div className={styles.me}>
                <NavLink to={pathTo(me)}>
                  <UserAvatar user={me}/>
                  <span>Hi {me.firstname}.</span>
                </NavLink>
              </div>
              <HeaderLogout/>
            </>
          )}
        </div>
      </Container>
    </header>
  );
}

export default Header;
