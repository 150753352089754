import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';

import Button from '../button';
import Popup from '../popup';
import PopupAction from '../popup/action';
import PopupSection from '../popup/section';
import TimeInWords from '../time/inWords';

const UPDATE_TODO = gql`
  mutation UpdateTodo($id: ID!, $estimateInSeconds: Int) {
    updateTodo(id: $id, estimateInSeconds: $estimateInSeconds) {
      id
      name
      dueAt
    }
  }
`;

function EstimateSelector({ todo, client }) {
  const [showPopup, setShowPopup] = useState(false);
  const [setEstimateInSeconds] = useMutation(UPDATE_TODO, {
    update(cache, { data: { updateTodo } }) {
      client.resetStore();
      setShowPopup(false);
    }
  });

  const predefinedButtons = [[null, "None"], [900, "15m"], [1800, "30m"], [2700, "45m"], [3600, "1h"], [7200, "2h"]].map((time, index) => (
    <PopupAction
      key={index}
      onClick={ event => {
        setEstimateInSeconds({
          variables: {
            id: todo.id,
            estimateInSeconds: time[0]
          }
        })
      }}
    >
      <span>{time[1]}</span>
    </PopupAction>
  ));

  return (
    <Popup show={showPopup} togglePopup={setShowPopup}>
      <Button variant="secondary" onClick={() => setShowPopup(true)}>
        {( todo.estimateInSeconds &&
          <TimeInWords seconds={todo.estimateInSeconds}/>
        )}
        {( !todo.estimateInSeconds &&
          <span>No Estimate</span>
        )}
      </Button>
      <>
        <PopupSection>
          {predefinedButtons}
        </PopupSection>
      </>
    </Popup>
  )
}

export default withApollo(EstimateSelector);
