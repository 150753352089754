import React from 'react';

import TimeAgo from '../time/ago';
import TodoActions from '../../components/todo/actions';
import TodoCheckbox from './checkbox';
import styles from './header.module.scss';

function TodoHeader({ todo }) {
  return (
    <header className={styles.header}>
      <div className={styles.checkbox}>
        <TodoCheckbox id={todo.id} done={todo.done}/>
      </div>
      <div className={styles.info} data-done={todo.done}>
        <div className={styles.name}>{todo.name}</div>
        <div className={styles.meta}>
          <div className={styles.number}>{todo.number}</div>
          <div className={styles.creator}>
            <div>Added</div>
            {( todo.creator &&
              <div className={styles.creator}>by<b>{todo.creator.firstname} {todo.creator.lastname[0]}.</b></div>
            )}
            <div className={styles.created}><TimeAgo timestamp={todo.createdAt}/></div>.
          </div>
        </div>
      </div>
      <div className={styles.actions }>
        <TodoActions todo={todo}/>
      </div>
    </header>
  );
}

export default TodoHeader;
