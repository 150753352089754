import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Loading from '../../components/loading';
import Layout from '../../components/layout';
import SpaceHeader from '../../components/space/header';
import TodoBody from '../../components/todo/body';
import TodoHeader from '../../components/todo/header';
import Title from '../../components/title';

const GET_TODO = gql`
  query GetTodo($id: ID!){
    todo(id: $id) {
      id
      name
      number
      done
      dueAt
      createdAt
      description
      estimateInSeconds
      workloadInSeconds
      progressInPercent
      assignee {
        id
        firstname
        lastname
        username
      }
      creator {
        id
        firstname
        lastname
        username
      }
      timelogs {
        id
        state
        startedAt
        loggedAt
        workloadInSeconds
        description
        user {
          id
          firstname
          lastname
          username
        }
      }
      space {
        id
        name
        key
        updatedAt
        archivedAt
        users {
          id
          firstname
          lastname
          username
        }
      }
    }
  }
`;

function Todo({ match }) {
  const todoId = parseInt(match.params.id, 10);

  const { loading, error, data } = useQuery(GET_TODO, {
    variables: {
      id: todoId
    }
  });

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  const { todo } = data;

  if (todo === null) {
    return (
      <Layout>
        Todo not found.
      </Layout>
    )
  }

  return (
    <>
      <Title title={`${todo.number} - ${todo.name}`}/>
      <Layout>
        <SpaceHeader space={todo.space}/>
        <TodoHeader todo={todo}/>
        <TodoBody todo={todo}/>
      </Layout>
    </>
  );
}

export default Todo;


//

  // <SpaceHeader space={space}/>
  // <ListsListing lists={space.lists}/>
