import React from 'react';
import moment from 'moment';
import 'moment-duration-format';

function TimeInWords({ seconds }) {
  const words = secondsToTimeInWords(seconds);

  return (
    <span>{words}</span>
  );
}

export default TimeInWords;

export function secondsToTimeInWords(seconds) {
  if (seconds === null || seconds === undefined || seconds === 0) {
    return null;
  }
  
  return moment.duration(seconds, "seconds").format('H[h] m[m]').replace(/(\s0h | 0m)/, '');
}

export function wordsToTimeInSeconds(words) {
  const daysRegex = /(\S+)d/;
  const daysMatch = daysRegex.exec(words);
  const days = daysMatch !== null ? daysMatch[1] : 0;

  const hoursRegex = /(\S+)h/;
  const hoursMatch = hoursRegex.exec(words);
  const hours = hoursMatch !== null ? hoursMatch[1] : 0;

  const minutesRegex = /(\S+)m/;
  const minutesMatch = minutesRegex.exec(words);
  const minutes = minutesMatch !== null ? minutesMatch[1] : 0;

  const secondsRegex = /(\S+)s/;
  const secondsMatch = secondsRegex.exec(words);
  const seconds = secondsMatch !== null ? secondsMatch[1] : 0;

  const total = (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;

  return parseInt(total, 10);
}
