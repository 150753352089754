import React from 'react';

import Container from '../container';
import styles from './index.module.scss';

function Header({ user }) {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.note}>
          This is an UNKNOWN STUDIOS service. © {new Date().getFullYear()}
        </div>
      </Container>
    </footer>
  );
}

export default Header;
