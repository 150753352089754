import React from 'react';

import styles from './avatar.module.scss';

function UserAvatar({ user }) {
  return (
    <div className={styles.avatar}>
      <div className={styles.initials}>{user.firstname[0]}{user.lastname[0]}</div>
    </div>
  );
}

export default UserAvatar;
