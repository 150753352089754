import React from 'react';

import TimelogForm from './form';
import Modal from '../modal';

function TimelogFormModal({ timelog, todo, onToggle }) {
  const title = timelog ? 'Editing timelog' : 'Add a new timelog';

  return (
    <Modal name={title} onClose={event => onToggle(false)}>
      <TimelogForm timelog={timelog} todo={todo} onSuccess={event => onToggle(false)}/>
    </Modal>
  );
}

export default TimelogFormModal;
