import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/layout';
import Loading from '../../components/loading';
import SpaceFooter from '../../components/space/footer';
import SpaceHeader from '../../components/space/header';
import SpaceScheduleCalendar from '../../components/space/scheduleCalendar';
import Title from '../../components/title';

const GET_SPACE = gql`
  query GetSpace($id: ID!){
    me {
      id
      firstname
      lastname
    }

    space(id: $id) {
      id
      name
      key
      updatedAt
      archivedAt
      progressInPercent
      estimateInSeconds
      workloadInSeconds

      client {
        id
        name
        token
      }

      lists {
        id
        name
        createdAsDefault
        default

        todos {
          id
          name
          number
          done
          dueAt
          assignee {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`;

function SpaceSchedule({ match }) {
  const spaceId = parseInt(match.params.id, 10);

  const { loading, error, data } = useQuery(GET_SPACE, {
    variables: {
      id: spaceId
    }
  });

  if (loading) return <Loading/>;
  if (error) return `Error! ${error.message}`;

  const { space, me } = data;

  if (space === null) {
    return (
      <Layout me={me}>
        Space not found.
      </Layout>
    )
  }

  return (
    <>
      <Title title={`${space.key} - ${space.name}`}/>
      <Layout me={me}>
        <SpaceHeader space={space}/>
        <SpaceScheduleCalendar space={space}/>
        <SpaceFooter space={space}/>
      </Layout>
    </>
  );
}

export default SpaceSchedule;
