import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';

import Button from '../button';
import { confirmDialog } from '../confirmation/dialog';
import ListForm from '../../components/list/form';
import Modal from '../../components/modal';
import Progress from '../progress';
import styles from './header.module.scss';

const DELETE_LIST = gql`
  mutation DeleteList($id: ID!) {
    deleteList(id: $id) {
      id
      name
    }
  }
`;

function ListsListingHeader({ list, doneCount, totalCount, client }) {
  const [showFormModal, setShowFormModal] = useState(false);
  const [deleteList] = useMutation(DELETE_LIST, {
    update(cache, { data: { deleteList } }) {
      client.resetStore();
    }
  });

  return (
    <header className={styles.header}>
      <div className={styles.name}>
        {list.name}
      </div>
      <div className={styles.completed}>
        {doneCount}/{totalCount} completed
      </div>
      <div className={styles.workload}>
        <Progress progressInPercent={list.progressInPercent} estimateInSeconds={list.plannedWorkloadInSeconds} workloadInSeconds={list.loggedWorkloadInSeconds}/>
      </div>
      <div className={styles.actions}>
        <Button
          size='small'
          variant='link'
          onClick={event => setShowFormModal(true)}
        >
          <i className="far fa-edit"/>
        </Button>
        {( !list.default &&
          <Button
            size='small'
            variant='link'
            onClick={event => {
              confirmDialog(() => {
                deleteList({
                  variables: {
                    id: list.id
                  }
                })
              })
            }}
          >
            <i className="far fa-trash-alt"/>
          </Button>
        )}
      </div>
      {( showFormModal &&
        <Modal name='Get ready to edit this list!' onClose={event => setShowFormModal(false)}>
          <ListForm list={list} onSuccess={event => setShowFormModal(false)}/>
        </Modal>
      )}
    </header>
  );
}

export default withApollo(ListsListingHeader);
