import React from 'react';

import styles from './space.module.scss';

function HeaderSearchResultSpace({ name, spaceKey, client }) {
  return (
    <div className={styles.space}>
      <div className={styles.icon}>
        <i className="fas fa-box-open"/>
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.key}>{spaceKey}</div>
      <div className={styles.client}>Client: {client}</div>
    </div>
  );
}

export default HeaderSearchResultSpace;
