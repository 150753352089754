import React from 'react';

import styles from './action.module.scss';

function PopupAction({ children, onClick }) {
  return (
    <div className={styles.action} onClick={onClick}>
      {children}
    </div>
  );
}

export default PopupAction;
