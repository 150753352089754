import React from 'react';

import styles from './todo.module.scss';

function HeaderSearchResultTodo({ name, number, done }) {
  const icon = done ? 'far fa-check-square' : 'far fa-square';

  return (
    <div className={styles.todo}>
      <div className={styles.icon}>
        <i className={icon}/>
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.number}>{number}</div>
    </div>
  );
}

export default HeaderSearchResultTodo;
