import React from 'react';

import styles from './select.module.scss';

function Select({ label, value, options, onChange }) {
  const selected = value === null ? 0 : value;

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <select className={styles.select} value={selected} onChange={event => onChange(event.target.value)}>
        {options}
      </select>
    </div>
  );
}

export default Select;
