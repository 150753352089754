import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './logout.module.scss';

class HeaderLogout extends React.Component {
  constructor(props) {
    super(props);

    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    localStorage.removeItem('authentication-token');
    this.props.history.push('/login');
  }

  render() {
    return (
      <div className={styles.logout} onClick={this.onLogout}>
        Logout
      </div>
    );
  }
}

export default withRouter(HeaderLogout);
