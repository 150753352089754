import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Container from '../container';
import Footer from '../footer';
import Header from '../header';
import styles from './index.module.scss';

const GET_CURRENT_USER = gql`
  {
    me {
      id
      firstname
      lastname
      username
    }
  }
`;

function Layout({ children }) {
  const { error, data } = useQuery(GET_CURRENT_USER);

  if (error) return `Error! ${error.message}`;

  const { me } = data;

  return (
    <div className={styles.app}>
      <Header me={me}/>
      <main>
        <Container>
          {children}
        </Container>
      </main>
      <Footer/>
    </div>
  );
}

export default Layout;
