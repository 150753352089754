import React from 'react';

import styles from './index.module.scss';

function spinner() {
  return (
    <>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      	<circle className="length" fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      	<circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      	<circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      	<circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
    </>
  )
}

function Loading() {
  return (
    <div className={styles.loading}>
      <div className={styles.spinner}>
        {spinner()}
      </div>
    </div>
  );
}

export default Loading;
