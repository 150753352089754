import React from 'react';
import moment from 'moment';

export default function DateFormat({ date, format='ddd, MMM Do, YYYY' }) {
  const dateString = moment(date).format(format);

  return (
    <span>{dateString}</span>
  );
}

export function isToday(date) {
  return isOn(date, new Date());
}

export function isForToday(date) {
  return isBefore(date, new Date());
}

export function isOn(is, on) {
  return moment(is).isSame(on, "day");
}

export function isBefore(is, before) {
  const day = moment(before).add(1, 'days');
  return moment(is).isBefore(day, "day");
}

export function isWithinThisWeek(date) {
  return moment(date).isSameOrBefore(endOfWeek(), 'day');
}

export function endOfWeek() {
  return moment().endOf('ISOweek');
}
