import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Select from '../forms/select';

const GET_SPACES = gql`
  {
    spaces {
      id
      name
    }
  }
`;

function SpacesSelect({ spaceId, onChange }) {

  const { loading, error, data } = useQuery(GET_SPACES);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const { spaces } = data;

  if (spaces.length === 0) {
    return <div>No spaces found.</div>;
  }

  if (spaceId === '') {
    onChange(parseInt(spaces[0].id, 10));
  }

  const options = spaces.map(space => <option key={space.id} value={space.id}>{space.name}</option>);

  return (
    <Select label='Space' value={spaceId} options={options} onChange={value => onChange(parseInt(value, 10))}/>
  );
}

export default SpacesSelect;
