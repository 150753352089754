import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

import styles from './renderer.module.scss';

function TextRenderer({ text }) {
  return (
    <div className={styles.text}>
      <ReactMarkdown source={text} plugins={[breaks]}/>
    </div>
  );
}

export default TextRenderer;
