import React from 'react';

import styles from './bar.module.scss';

function ProgressBar({ progressInPercent }) {
  let classification = 'normal';

  if (progressInPercent > 90) {
    classification = 'warning';
  }

  if (progressInPercent > 110) {
    classification = 'danger';
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.bar} style={{ width: `${progressInPercent}%`}} data-classification={classification}/>
    </div>
  );
}

export default ProgressBar;
