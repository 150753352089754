import React from 'react';

import WorkloadRing from './ring';
import styles from './rings.module.scss';

function WorkloadRings({ workload }) {
  const rings = workload.map((workloadByDay, index) => <WorkloadRing key={index} workload={workloadByDay}/>);

  return (
    <div className={styles.workload}>
      <div className={styles.scrolling}>
        <div className={styles.rings}>
          {rings}
        </div>
      </div>
    </div>
  );
}

export default WorkloadRings;
