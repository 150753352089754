import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { pathTo } from '../../routes';
import styles from './scheduleCalendar.module.scss';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

function SpaceScheduleCalendar({ space }) {

  const events = space.lists.map(list => list.todos).flat().filter(todo => !todo.done).map(todo => {
    return {
      title: todo.name,
      date: todo.dueAt,
      allDay: true,
      url: pathTo(todo)
    }
  });

  return (
    <section className={styles.schedule}>
      <FullCalendar
        events={events}
        defaultView="dayGridMonth"
        firstDay={1}
        plugins={[ dayGridPlugin ]}
      />
    </section>
  );
}

export default SpaceScheduleCalendar;
