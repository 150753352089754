import React from 'react';

import styles from './bar.module.scss';

function ButtonBar({ children, align }) {
  return (
    <div className={styles.bar} data-align={align}>
      {children}
    </div>
  );
}

export default ButtonBar;
