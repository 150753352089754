import React from 'react';
import { NavLink } from 'react-router-dom';

import { pathTo } from '../../routes';
import UserAvatar from './avatar';

import styles from './info.module.scss';

function UserInfo({ user }) {
  return (
    <NavLink to={pathTo(user)} className={styles.info}>
      <UserAvatar user={user}/>
      <div className={styles.name}>{user.firstname} {user.lastname[0]}.</div>
    </NavLink>
  );
}

export default UserInfo;
