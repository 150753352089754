import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Droppable, Draggable } from "react-beautiful-dnd";

import TimeDate from '../../time/date';
import TodoCard from '../../todo/card';

import styles from './day.module.scss';

const GET_USER_WORKLOAD_FOR_DAY = gql`
  query GetUserWorkloadForDay($date: ISO8601DateTime!, $username: String!){
    user(username: $username) {
      id

      workload: workloadOn(on: $date) {
        plannedWorkloadInPercentForDay
        remainingWorkloadInPercentForDay
        todos {
          id
          name
          number
          space {
            id
            name
            key
          }
        }
      }
    }
  }
`;

function UserWorkloadCalendarDay({ user, index }) {
  const day = moment().add(index, 'days').startOf('day').add(5, "hours");

  const { loading, error, data } = useQuery(GET_USER_WORKLOAD_FOR_DAY, {
    variables: {
      date: day.toISOString(),
      username: user.username
    }
  });

  if (loading) return (
    <div className={styles.day} key={'test'}>
      <div className={styles.header}>
        <TimeDate date={day} format="ddd, MMM Do"/>
      </div>
      <div className={styles.loading}>
        Loading
      </div>
    </div>
  );

  if (error) return `Error! ${error.message}`;

  const { plannedWorkloadInPercentForDay, remainingWorkloadInPercentForDay, todos } = data.user.workload;

  const todoCards = todos.map(todo => (
    <Draggable key={todo.id} draggableId={todo.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={styles.draggable}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TodoCard key={todo.id} todo={todo}/>
        </div>
      )}
    </Draggable>
  ));



  return (
    <div className={styles.day} key={'workload-todos-day-list'}>
      <div className={styles.header}>
        <TimeDate date={day} format="ddd, MMM Do"/>
      </div>
      <div className={styles.body}>
        <div className={styles.workload}>
          <div className={styles.planned} style={{ width: `${plannedWorkloadInPercentForDay}%` }}/>
          <div className={styles.remaining} style={{ width: `${remainingWorkloadInPercentForDay}%` }}/>
        </div>
        <div className={styles.todos}>
          <Droppable droppableId={day.toISOString()}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {todoCards}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
}

export default UserWorkloadCalendarDay;
