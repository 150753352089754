import React from 'react';

import Button from '../button';
import ButtonBar from '../button/bar';
import Progress from '../progress';
import Timeago from '../time/ago';
import styles from './footer.module.scss';

function SpaceFooter({ space }) {
  return (
    <footer className={styles.footer}>
      <div className={styles.section}>
        <div className={styles.name}>
          Client
        </div>
        <div className={styles.body}>
          {space.client.name} - Token: {space.client.token}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.name}>
          Updated at
        </div>
        <div className={styles.body}>
          <Timeago timestamp={space.updatedAt}/>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.name}>
          Progress
        </div>
        <div className={styles.body}>
          <Progress progressInPercent={space.progressInPercent} workloadInSeconds={space.workloadInSeconds} estimateInSeconds={space.estimateInSeconds}/>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.name}>
          Manage
        </div>
        <div className={styles.body}>
          <ButtonBar align='left'>
            <Button label='Edit' variant='secondary'/>
            <Button label='Delete' variant='danger'/>
          </ButtonBar>
        </div>
      </div>
    </footer>
  );
}

export default SpaceFooter;

// TODO: edit and delete need to be added
