import React, { useState } from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Alert from '../alert';
import Button from '../button';
import DatePicker from '../forms/datePicker';
import Fieldset from '../forms/fieldset';
import Input from '../forms/input';
import Select from '../forms/select';
import Textarea from '../forms/textarea';
import { secondsToTimeInWords, wordsToTimeInSeconds } from '../time/inWords';
import styles from './form.module.scss';

const UPDATE_TODO = gql`
  mutation UpdateTodo($id: ID!, $name: String, $assigneeId: Int, $estimateInSeconds: Int, $dueAt: ISO8601DateTime, $description: String) {
    updateTodo(id: $id, name: $name, assigneeId: $assigneeId, estimateInSeconds: $estimateInSeconds, dueAt: $dueAt, description: $description) {
      id
      name
      estimateInSeconds
      dueAt
      description
      assignee {
        id
        firstname
        lastname
      }
      creator {
        id
        firstname
        lastname
      }
    }
  }
`;

function TodoForm({ todo, onSuccess, client }) {
  const [name, setName] = useState(todo.name);
  const [assigneeId, setAssigneeId] = useState(todo.assignee && todo.assignee.id);
  const [estimate, setEstimate] = useState(todo.estimateInSeconds ? secondsToTimeInWords(todo.estimateInSeconds) : null);
  const [dueAt, setDueAt] = useState(todo.dueAt);
  const [description, setDescription] = useState(todo.description);
  const [error, setError] = useState(null);

  const assignees = [{ id: null, firstname: 'No', lastname: 'Body'}, ...todo.space.users].map(user => <option key={user.id} value={user.id}>{user.firstname} {user.lastname}</option>);

  return (
    <Mutation
      mutation={UPDATE_TODO}
      variables={{ id: todo.id, name, assigneeId: parseInt(assigneeId, 10), estimateInSeconds: (estimate ? wordsToTimeInSeconds(estimate) : null), dueAt, description }}
      update={(store, { data: { addTodo } }) => {
        client.resetStore();
        onSuccess();
        setError(null);
      }}
      onError={({ message }) => {
        setError(message);
      }}
    >
      {(mutation, loading) => {
        return (
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
            }
          }>
            {( error &&
              <Fieldset>
                <Alert message={error} onClose={event => setError(null)}/>
              </Fieldset>
            )}
            <Fieldset>
              <Input label='Name' value={name} autofocus={true} onChange={setName}/>
            </Fieldset>
            <Fieldset>
              <Select label='Assignee' options={assignees} value={assigneeId} onChange={setAssigneeId}/>
            </Fieldset>
            <Fieldset>
              <Input label='Estimate' value={estimate} onChange={setEstimate}/>
            </Fieldset>
            <Fieldset>
              <DatePicker label='Due at' value={dueAt} onChange={setDueAt}/>
            </Fieldset>
            <Fieldset>
              <Textarea label='Description' value={description} onChange={setDescription}/>
            </Fieldset>

            <Fieldset>
              <Button
                label='Save'
                onClick={event => {
                  mutation();
                }}
              />
            </Fieldset>
          </form>
        )
      }}
    </Mutation>
  );
}

export default withApollo(TodoForm);
