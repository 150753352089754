import React, { useState } from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Alert from '../alert';
import Button from '../button';
import Fieldset from '../forms/fieldset';
import Input from '../forms/input';
import styles from './form.module.scss';

const ADD_LIST = gql`
  mutation AddList($spaceId: Int!, $name: String!) {
    addList(spaceId: $spaceId, name: $name) {
      id
      name
    }
  }
`;

const UPDATE_LIST = gql`
  mutation UpdateList($id: ID!, $name: String!) {
    updateList(id: $id, name: $name) {
      id
      name
    }
  }
`;

function ListForm({ spaceId, list, onSuccess, client }) {
  const [name, setName] = useState(list ? list.name : '');
  const [error, setError] = useState(null);

  const mutation = list ? UPDATE_LIST : ADD_LIST;
  const variables = list ? { id: parseInt(list.id, 10) } : { spaceId: parseInt(spaceId, 10) };

  return (
    <Mutation
      mutation={mutation}
      variables={{ ...variables, name }}
      update={(store, { data: { addList } }) => {
        client.resetStore();
        onSuccess();
        setError(null);
      }}
      onError={({ message }) => {
        setError(message);
      }}
    >
      {(mutation, loading) => {
        return (
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
            }
          }>
            {( error &&
              <Fieldset>
                <Alert message={error} onClose={event => setError(null)}/>
              </Fieldset>
            )}
            <Fieldset>
              <Input label='Name' value={name} autofocus={true} onChange={setName}/>
            </Fieldset>

            <Fieldset>
              <Button
                label='Save'
                onClick={event => {
                  mutation();
                }}
              />
            </Fieldset>
          </form>
        )
      }}
    </Mutation>
  );
}

export default withApollo(ListForm);
