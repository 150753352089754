import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Select from '../forms/select';

const GET_CLIENTS = gql`
  {
    clients {
      id
      name
    }
  }
`;

function ClientsSelect({ clientId, onChange }) {

  const { loading, error, data } = useQuery(GET_CLIENTS);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const { clients } = data;

  if (clients.length === 0) {
    return <div>No clients found.</div>;
  }

  if (clientId === '') {
    onChange(parseInt(clients[0].id, 10));
  }

  const options = clients.map(client => <option key={client.id} value={client.id}>{client.name}</option>);

  return (
    <Select label='Client' value={clientId} options={options} onChange={value => onChange(parseInt(value, 10))}/>
  );
}

export default ClientsSelect;
