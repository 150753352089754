import React from 'react';

import styles from './status.module.scss';

function InvoiceStatus({ status }) {
  return (
    <div className={styles.status} data-status={status}>
      {status}
    </div>
  );
}

export default InvoiceStatus;
