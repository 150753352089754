import React from 'react';

import ProgressBar from './bar';
import TimeInWords from '../time/inWords';
import styles from './index.module.scss';

function Progress({ progressInPercent, estimateInSeconds, workloadInSeconds }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.workload}>
        <TimeInWords seconds={workloadInSeconds}/>
      </div>
      <div className={styles.progress}>
        <ProgressBar progressInPercent={progressInPercent}/>
      </div>
      <div className={styles.estimate}>
        <TimeInWords seconds={estimateInSeconds}/>
      </div>
    </div>
  );
}

export default Progress;
