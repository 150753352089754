import React, { useState } from 'react';
import { Droppable, Draggable } from "react-beautiful-dnd";

import TodosListingItem from './listingItem';
import styles from './listing.module.scss';

function getList(todos, listId, sortable, displayLimit) {
  const items = todos.slice(0, displayLimit).map((todo, index) => {
    if (sortable) {
      return (
        <Draggable key={todo.id} draggableId={todo.id} index={index}>
          {(provided, snapshot) => (
            <div
              className={styles.draggable}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <TodosListingItem todo={todo}/>
            </div>
          )}
        </Draggable>
      )
    } else {
      return <TodosListingItem key={todo.id} todo={todo}/>;
    }
  });

  if (sortable) {
    return (
      <>
        <div className={styles.todos}>
          <Droppable droppableId={`list-${listId}`}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.todos}>
        {items}
      </div>
    );
  }
}

function TodosListing({ todos, listId, sortable=false, limit=10000 }) {
  const [displayLimit, setDisplayLimit] = useState(limit);

  if (sortable === false && todos.length === 0) {
    return null;
  }

  return (
    <>
      {getList(todos, listId, sortable, displayLimit)}
      {( todos.length > displayLimit &&
        <div className={styles.more} onClick={event => setDisplayLimit(100000)}>Show {todos.length - displayLimit} additional todos</div>
      )}
    </>
  )
}

export default TodosListing;
