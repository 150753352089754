import React from 'react';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

import styles from './input.module.scss';

function Textarea({ label, value, onChange, editor=true, autofocus=null }) {
  return (
    <div className={styles.wrapper}>
      {( label &&
        <label className={styles.label}>{label}</label>
      )}
      {( !editor &&
        <textarea className={styles.input} value={value} onChange={event => onChange(event.target.value)} autoFocus={autofocus}/>
      )}
      {( editor &&
        <SimpleMDE value={value} onChange={value => onChange(value)}/>
      )}
    </div>
  );
}

export default Textarea;
