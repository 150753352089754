import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Authentication from '../authentication';
import Dashboard from '../dashboard';
import Invoice from '../invoice';
import Invoices from '../invoices';
import Space from '../space';
import Spaces from '../spaces';
import SpaceSchedule from '../spaceSchedule';
import Todo from '../todo';
import UserProfile from '../userProfile';

class App extends React.Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('authentication-token');

    this.state = {
      token
    }

    if (token === null) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps(props) {
    const token = localStorage.getItem('authentication-token');

    if (token !== null) {
      this.setState({ token: token });
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path='/login' component={Authentication}/>
        <Route exact path='/' component={Dashboard}/>
        <Route exact path='/spaces' component={Spaces}/>
        <Route exact path='/spaces/:id' component={Space}/>
        <Route exact path='/spaces/:id/schedule' component={SpaceSchedule}/>
        <Route exact path='/todos/:id' component={Todo}/>
        <Route exact path='/invoices/' component={Invoices}/>
        <Route exact path='/invoices/:id' component={Invoice}/>
        <Route exact path='/users/:username' component={UserProfile}/>
      </Switch>
    );
  }
}

export default withRouter(App);
