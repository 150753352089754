import React from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Button from '../button';

const START_TIMER = gql`
  mutation StartTimer($todoId: Int!, $userId: Int!) {
    startTimer(todoId: $todoId, userId: $userId) {
      id
      state
      startedAt
      loggedAt
      workloadInSeconds
      description
      user {
        id
        firstname
        lastname
      }
    }
  }
`;

const STOP_TIMER = gql`
  mutation StopTimer($id: ID!) {
    stopTimer(id: $id) {
      id
      state
      startedAt
      loggedAt
      workloadInSeconds
      description
      user {
        id
        firstname
        lastname
      }
    }
  }
`;

function TimerButton({ todo, timelog, client, size }) {
  // TODO: current user id need to be here
  const runningTimelog = timelog ? timelog : todo.timelogs.find(timelog => timelog.state === 'running' && timelog.user.id === "1");
  const mutationQuery = runningTimelog ? STOP_TIMER : START_TIMER;
  // TODO: current user id need to be here
  const variables = runningTimelog ? { id: parseInt(runningTimelog.id, 10), userId: 1 } : { todoId: parseInt(todo.id, 10), userId: 1 };

  return (
    <Mutation
      mutation={mutationQuery}
      variables={variables}
      update={(store, { data: { updateTimelog } }) => {
        client.resetStore();
      }}
    >
      {(mutation, loading) => {
        if (runningTimelog) {
          return (
            <Button variant="timer" onClick={mutation} size={size}>
              <i className="far fa-clock fa-spin"/>
            </Button>
          );
        } else {
          return (
            <Button onClick={mutation} size={size}>
              <i className="far fa-clock"/>
            </Button>
          );
        }
      }}
    </Mutation>
  )
}

export default withApollo(TimerButton);
