import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import styles from './checkbox.module.scss';

const UPDATE_TODO_DONE_STATUS = gql`
  mutation UpdateTodoDoneStatus($id: ID!, $done: Boolean!) {
    updateTodo(id: $id, done: $done) {
      id
      name
      number
      done
      position
    }
  }
`;

function TodoCheckbox({ id, done }) {
  return (
    <Mutation
      key={id}
      mutation={UPDATE_TODO_DONE_STATUS}
      variables={{ id, done: !done }}
    >
      {(mutation, loading) => {
        return (
          <div className={styles.checkbox} data-done={done} onClick={mutation}/>
        )
      }}
    </Mutation>
  );
}

export default TodoCheckbox;
