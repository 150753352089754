import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClickOutside from '../clickOutside';
import styles from './index.module.scss';

class Modal extends Component {
  componentWillMount() {
    document.body.classList.add('fixed');
  }

  componentWillUnmount() {
    document.body.classList.remove('fixed');
  }

  render() {
    const { name, children, onClose } = this.props;

    if (!name || !children || !onClose) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.modal}>
          <ClickOutside onClick={onClose}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.name}>{name}</div>
                <div className={styles.close} onClick={onClose}>
                  <i className="fas fa-times"/>
                </div>
              </div>
              <div className={styles.body}>
                {children}
              </div>
            </div>
          </ClickOutside>
        </div>
        <div className={styles.backdrop}/>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Modal;
