import React, { useState } from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Alert from '../alert';
import Button from '../button';
import Checkbox from '../forms/checkbox';
import Fieldset from '../forms/fieldset';
import Input from '../forms/input';
import ClientsSelect from '../clients/select';
import styles from './form.module.scss';

const ADD_SPACE = gql`
  mutation AddSpace($name: String!, $key: String!, $clientId: Int!) {
    addSpace(name: $name, key: $key, clientId: $clientId) {
      id
      name
      updatedAt
      archivedAt
      key
      client {
        name
      }
    }
  }
`;

const UPDATE_SPACE = gql`
  mutation UpdateSpace($id: ID!, $name: String!, $key: String!, $clientId: Int!, $archivedAt: ISO8601DateTime) {
    updateSpace(id: $id, name: $name, key: $key, clientId: $clientId, archivedAt: $archivedAt) {
      id
      name
      updatedAt
      archivedAt
      key
      client {
        id
        name
      }
    }
  }
`;

function getArchivedStatus(value, setArchivedAt) {
  if (value) {
    const now = new Date();
    setArchivedAt(now.toISOString());
  } else {
    setArchivedAt(null);
  }
}

function SpaceForm({ space, client, onSuccess }) {
  const id = space && space.id;
  const [name, setName] = useState(space ? space.name : '');
  const [key, setKey] = useState(space ? space.key.replace('#', '') : '');
  const [clientId, setClientId] = useState(space ? parseInt(space.client.id, 10) : '');
  const [archivedAt, setArchivedAt] = useState(space ? space.archivedAt : null);
  const [error, setError] = useState(null);
  const mutationQuery = space ? UPDATE_SPACE : ADD_SPACE;

  return (
    <Mutation
      mutation={mutationQuery}
      variables={{ id: id, name, key, clientId, archivedAt }}
      update={(store, { data: { addSpace } }) => {
        client.resetStore();
        onSuccess();
        setError(null);
      }}
      onError={({ message }) => {
        setError(message);
      }}
    >
      {(mutation, loading) => {
        return (
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
            }
          }>
            {( error &&
              <Fieldset>
                <Alert message={error} onClose={event => setError(null)}/>
              </Fieldset>
            )}
            <Fieldset>
              <Input label='Name' value={name} autofocus={true} onChange={setName}/>
            </Fieldset>
            <Fieldset>
              <Input label='Key' value={key} onChange={setKey}/>
            </Fieldset>
            <Fieldset>
              <ClientsSelect clientId={clientId} onChange={value => setClientId(value)}/>
            </Fieldset>
            <Fieldset>
              <Checkbox label='Archived' value={archivedAt !== null} onChange={value => getArchivedStatus(value, setArchivedAt)}/>
            </Fieldset>
            <Fieldset>
              <Button
                label='Save'
                onClick={event => {
                  mutation();
                }}
              />
            </Fieldset>
          </form>
        )
      }}
    </Mutation>
  );
}

export default withApollo(SpaceForm);
