import React from 'react';
import { NavLink } from 'react-router-dom';

import { pathTo } from '../../routes';
import styles from './header.module.scss';

function SpaceHeader({ space }) {
  return (
    <header className={styles.header}>
      <div className={styles.name}>{space.name}</div>

      <div className={styles.nav}>
        <NavLink className={styles.item} activeClassName={styles.active} exact to={pathTo(space)}>
          To-dos
        </NavLink>
        <NavLink className={styles.item} activeClassName={styles.active} exact to='#' disabled>
          Discussions
        </NavLink>
        <NavLink className={styles.item} activeClassName={styles.active} exact to={`${pathTo(space)}/schedule`} disabled>
          Schedule
        </NavLink>
        <NavLink className={styles.item} activeClassName={styles.active} exact to='#' disabled>
          Files
        </NavLink>
        <NavLink className={styles.item} activeClassName={styles.active} exact to='#' disabled>
          Settings
        </NavLink>
      </div>
    </header>
  );
}

export default SpaceHeader;
