import React from 'react';
import { NavLink } from 'react-router-dom';

import DateSelector from '../date/selector';
import { pathTo } from '../../routes';
import ProgressBar from '../progress/bar';
import TimeInWords from '../time/inWords';
import TodoCheckbox from '../todo/checkbox';
import UserInfo from '../user/info';
import styles from './listingItem.module.scss';

function TodosListingItem({ todo }) {
  return (
    <div className={styles.todo} data-done={todo.done}>
      <div className={styles.status}>
        <TodoCheckbox id={todo.id} done={todo.done}/>
        <div className={styles.checkbox}/>
      </div>
      <div className={styles.name}>
        <NavLink to={pathTo(todo)}>{todo.name}</NavLink>
      </div>
      <div className={styles.number}>{todo.number}</div>
      <div className={styles.date}>
        <DateSelector todo={todo}/>
      </div>
      {( todo.estimateInSeconds &&
        <div className={styles.estimate}>
          <div className={styles.label}>
            <TimeInWords seconds={todo.estimateInSeconds}/>
          </div>
        </div>
      )}
      <div className={styles.progress}><ProgressBar progressInPercent={todo.progressInPercent}/></div>
      {( todo.assignee &&
        <div className={styles.user}>
          <UserInfo user={todo.assignee}/>
        </div>
      )}
    </div>
  );
}

export default TodosListingItem;
