import React from 'react';
import DatePicker from "react-datepicker";

import styles from './datePicker.module.scss';

function DatePickerComponent({ label, value, onChange, autofocus = null }) {
  const selected = value === null ? null : new Date(value);

  return (
    <div className={styles.wrapper}>
      {( label &&
        <label className={styles.label}>{label}</label>
      )}
      <div className={styles.datepicker}>
        <DatePicker
          selected={selected}
          onChange={value => onChange(value && value.toISOString())}
        />
      </div>
    </div>
  );
}

export default DatePickerComponent;
