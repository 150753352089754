import React from 'react';

import InvoicesListingItem from './listingItem';
import styles from './listing.module.scss';

function InvoicesListing({ invoices }) {
  const items = invoices.map(invoice => <InvoicesListingItem key={invoice.id} invoice={invoice}/>);

  return (
    <div className={styles.container}>
      {items}
    </div>
  );
}

export default InvoicesListing;
