import React from 'react';
import { NavLink } from 'react-router-dom';

import DateFormat from '../date';
import InvoiceStatus from '../invoice/status';
import { pathTo } from '../../routes';
import styles from './listingItem.module.scss';

function InvoicesListingItem({ invoice }) {
  return (
    <div className={styles.invoice}>
      <div className={styles.status}>
        <InvoiceStatus status={invoice.state}/>
      </div>
      <div className={styles.number}>
        <NavLink to={pathTo(invoice)}>{invoice.number}</NavLink>
      </div>
      <div className={styles.client}>
        <div>{invoice.client.name}</div>
        <div className={styles.space}>
          {invoice.space.name}
        </div>
      </div>
      <div className={styles.name}>
        {invoice.name}
      </div>
      <div className={styles.date}>
        <DateFormat date={invoice.createdAt}/>
      </div>
    </div>
  );
}

export default InvoicesListingItem;
