import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Button from '../../components/button';
import ButtonBar from '../../components/button/bar';
import Layout from '../../components/layout';
import ListForm from '../../components/list/form';
import ListsListing from '../../components/lists/listing';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import SpaceFooter from '../../components/space/footer';
import SpaceHeader from '../../components/space/header';
import Title from '../../components/title';

const GET_SPACE = gql`
  query GetSpace($id: ID!){
    space(id: $id) {
      id
      name
      key
      updatedAt
      archivedAt
      progressInPercent
      estimateInSeconds
      workloadInSeconds

      client {
        id
        name
        token
      }

      users {
        id
        firstname
        lastname
      }

      lists {
        id
        name
        createdAsDefault
        default
        plannedWorkloadInSeconds
        loggedWorkloadInSeconds
        remainingWorkloadInSeconds
        progressInPercent

        todos {
          id
          name
          number
          done
          dueAt
          progressInPercent
          estimateInSeconds
          position
          assignee {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`;

function Space({ match }) {
  const spaceId = parseInt(match.params.id, 10);
  const [showFormModal, setShowFormModal] = useState(false);

  const { loading, error, data } = useQuery(GET_SPACE, {
    variables: {
      id: spaceId
    }
  });

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  const { space } = data;

  if (space === null) {
    return (
      <Layout>
        Space not found.
      </Layout>
    )
  }

  return (
    <>
      <Title title={`${space.key} - ${space.name}`}/>
      <Layout>
        <SpaceHeader space={space}/>
        <ButtonBar>
          <Button label='Add List' onClick={event => setShowFormModal(true)}/>
        </ButtonBar>
        <ListsListing space={space}/>
        <SpaceFooter space={space}/>
      </Layout>
      {( showFormModal &&
        <Modal name='Get ready to add a new todo list!' onClose={event => setShowFormModal(false)}>
          <ListForm spaceId={space.id} onSuccess={event => setShowFormModal(false)}/>
        </Modal>
      )}
    </>
  );
}

export default Space;
