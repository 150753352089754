import React from 'react';

import TimelogsListingItem from './listingItem';
import styles from './listing.module.scss';

function TimelogsListing({ timelogs }) {

  if (timelogs.length === 0) {
    return (
      <div className={styles.empty}>There are currently no timelogs.</div>
    );
  }

  const items = timelogs.map(timelog => <TimelogsListingItem key={`${timelog.workloadInSeconds}${timelog.id}`} timelog={timelog}/>);

  return (
    <div className={styles.timelogs}>
      {items}
    </div>
  );
}

export default TimelogsListing;
