import React from 'react';

import styles from './index.module.scss';

function Button({ label, children, onClick = () => {}, type='submit', size='default', variant='default' }) {
  return (
    <button className={styles.button} type={type} onClick={onClick} data-size={size} data-style={variant}>
      {label}{children}
    </button>
  );
}

export default Button;
