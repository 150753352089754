import React from 'react';

import { isToday } from '../date';
import TimeInWords from '../time/inWords';
import TimeDate from '../time/date';
import styles from './ring.module.scss';

function WorkloadRing({ workload }) {
  const planned = `${workload.plannedWorkloadInPercentForDay}%`;
  const remaining = `${workload.remainingWorkloadInPercentForDay}%`;
  const logged = `${workload.loggedWorkloadInPercentForDay}%`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.date} data-today={isToday(workload.day)}>
        <TimeDate date={workload.day} format='ddd, MMM Do'/>
      </div>
      <div className={styles.ring}>
        <div className={styles.available}/>
        <div className={styles.planned} style={{ width: planned, height: planned }}/>
        <div className={styles.remaining} style={{ width: remaining, height: remaining }}/>
        <div className={styles.logged} style={{ width: logged, height: logged }}/>
      </div>
      <div className={styles.stats}>
        <div className={styles.available}>
          <b>Available <i className={styles.indicator}/></b> <TimeInWords seconds={workload.availableWorkloadInSecondsForDay}/>
        </div>
        <div className={styles.planned}>
          <b>Planned <i className={styles.indicator}/></b> <TimeInWords seconds={workload.plannedWorkloadInSecondsForDay}/>
        </div>
        <div className={styles.remaining}>
          <b>Remaining <i className={styles.indicator}/></b> <TimeInWords seconds={workload.remainingWorkloadInSecondsForDay}/>
        </div>
        <div className={styles.logged}>
          <b>Logged <i className={styles.indicator}/></b> <TimeInWords seconds={workload.loggedWorkloadInSecondsForDay}/>
        </div>
      </div>
    </div>
  );
}

export default WorkloadRing;
