import React from 'react';

import ClickOutside from '../clickOutside';
import styles from './index.module.scss';

function Popup({ children, show=false, togglePopup=() => {} }) {
  return (
    <>
      {children[0]}
      {( show &&
        <ClickOutside onClick={event => togglePopup(false)}>
          <div className={styles.popup}>
            {children[1]}
          </div>
        </ClickOutside>
      )}
    </>
  );
}

export default Popup;
