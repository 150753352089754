import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Loading from '../../components/loading';
import Layout from '../../components/layout';
import Title from '../../components/title';
import TimelogsListing from '../../components/timelogs/listing';
import TodosListingSmall from '../../components/todos/listingSmall';
import WorkloadRings from '../../components/workload/rings';
import styles from './index.module.scss';

const GET_DASHBOARD_DATA = gql`
  query DashboardData($from: ISO8601DateTime!, $to: ISO8601DateTime!) {
    me {
      id
      firstname
      lastname
      username
      workload: workloadBetween(from: $from, to: $to) {
        day
        availableWorkloadInSecondsForDay
        plannedWorkloadInPercentForDay
        loggedWorkloadInPercentForDay
        plannedWorkloadInSecondsForDay
        loggedWorkloadInSecondsForDay
        remainingWorkloadInSecondsForDay
        remainingWorkloadInPercentForDay
      }
      todos(scope: "today", done: false) {
        id
        name
        number
        done
        dueAt
        progressInPercent
        estimateInSeconds
        assignee {
          id
          firstname
          lastname
          username
        }
      }
      timers: timelogs(state: "running", scope: "today") {
        id
        state
        startedAt
        loggedAt
        workloadInSeconds
        description
        todo {
          id
          name
          number
        }
        user {
          id
          firstname
          lastname
          username
        }
      }
      timelogs(state: "logged", scope: "today") {
        id
        state
        startedAt
        loggedAt
        workloadInSeconds
        description
        todo {
          id
          name
          number
        }
        user {
          id
          firstname
          lastname
          username
        }
      }
    }
  }
`;

const from = new Date().toISOString();
const to = new Date(new Date().setDate(new Date().getDate() + 4)).toISOString();

function Dashboard() {
  const { loading, error, data } = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      from: from,
      to: to
    }
  });

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  const { me } = data;
  const { workload, todos, timers, timelogs } = me;

  return (
    <>
      <Title title='Dashboard'/>
      <Layout>
        <section className={styles.section}>
          <WorkloadRings workload={workload}/>
        </section>
        <section className={styles.section}>
          <div className={styles.name}>Your Today's Todos</div>
          <TodosListingSmall todos={todos}/>
        </section>
        <section className={styles.section}>
          <div className={styles.name}>Your Running Timers</div>
          <TimelogsListing timelogs={timers}/>
        </section>
        <section className={styles.section}>
          <div className={styles.name}>Your logged workload</div>
          <TimelogsListing timelogs={timelogs}/>
        </section>
      </Layout>
    </>
  );
}

export default Dashboard;
