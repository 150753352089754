import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Title from '../../components/title';
import styles from './index.module.scss';

const LOGIN_MUTATION = gql`
  mutation LoginUserMutation($login: String!, $password: String!) {
    loginUser(login: $login, password: $password) {
      token
    }
  }
`;

class Authentication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      error: undefined
    }

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  }

  submitForm(event, mutation) {
    event.preventDefault();
  }

  render() {
    const { login, password, error } = this.state;
    const that = this;

    return (
      <>
        <Title title='Login'/>
        <div className={styles.container}>
          <Mutation
            mutation={LOGIN_MUTATION}
            variables={{ login, password }}
            onCompleted={(data) => {
              localStorage.setItem('authentication-token', data.loginUser.token);
              this.props.history.push('/spaces');
            }}
            onError={(graphQLErrors, networkError) => {
              const split = graphQLErrors.toString().split(": ");
              const message = split[split.length - 1];

              that.setState({ error: message });
            }}
          >
            {(mutation) => (
              <form className={styles.form} onSubmit={(event) => this.submitForm(event, mutation)}>
                {( error !== undefined &&
                  <fieldset className={styles.fieldset}>
                    <div className={styles.alert}>{error}</div>
                  </fieldset>
                )}
                <fieldset className={styles.fieldset}>
                  <label className={styles.label}>Login</label>
                  <input className={styles.input} type="text" name="login" autoFocus={true} value={login} onChange={this.onInputChange}/>
                </fieldset>
                <fieldset className={styles.fieldset}>
                  <label className={styles.label}>Password</label>
                  <input className={styles.input} type="password" name="password" value={password} onChange={this.onInputChange}/>
                </fieldset>
                <fieldset className={styles.fieldset}>
                  <button className={styles.button} type="submit" onClick={mutation}>Login</button>
                </fieldset>
              </form>
            )}
          </Mutation>
        </div>
      </>
    );
  }
}

export default Authentication;
