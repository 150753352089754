import React from 'react';

import Progress from '../progress';
import UserInfo from '../user/info';
import TextRenderer from '../text/renderer';
import TimeDate from '../time/date';
import TimelogsListing from '../timelogs/listing';
import styles from './body.module.scss';

function TodoBody({ todo }) {
  return (
    <div className={styles.body}>
      {( todo.assignee &&
        <div className={styles.section}>
          <div className={styles.title}>Assigned to</div>
          <div className={styles.info}>
            <UserInfo user={todo.assignee}/>
          </div>
        </div>
      )}
      {( todo.dueAt &&
        <div className={styles.section}>
          <div className={styles.title}>Due at</div>
          <div className={styles.info}>
            <TimeDate date={todo.dueAt}/>
          </div>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.title}>Progress</div>
        <div className={styles.info}>
           <Progress progressInPercent={todo.progressInPercent} workloadInSeconds={todo.workloadInSeconds} estimateInSeconds={todo.estimateInSeconds}/>
        </div>
      </div>
      {( todo.description &&
        <div className={styles.section}>
          <div className={styles.title}>Description</div>
          <div className={styles.info}>
            <TextRenderer text={todo.description}/>
          </div>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.title}>Timelogs</div>
        <div className={styles.info}>
          <TimelogsListing timelogs={todo.timelogs}/>
        </div>
      </div>
    </div>
  );
}

export default TodoBody;
