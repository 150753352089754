import React from 'react';
import { NavLink } from 'react-router-dom';

import HeaderSearchResultSpace from './space';
import HeaderSearchResultTodo from './todo';
import { pathTo } from '../../../routes';
import styles from './result.module.scss';

function getContent({ result }) {
  switch (result.__typename) {
    case 'Space':
      return <HeaderSearchResultSpace name={result.name} spaceKey={result.key} client={result.client.name}/>
    case 'Todo':
      return <HeaderSearchResultTodo name={result.name} number={result.number} done={result.done}/>
    default:
      return null;
  }
}

function HeaderSearchResult({ result, selected, index, setActiveIndex }) {
  return (
    <NavLink to={pathTo(result)} className={styles.result} data-selected={selected} onMouseEnter={() => setActiveIndex(index)}>
      {getContent({ result })}
    </NavLink>
  );
}

export default HeaderSearchResult;
