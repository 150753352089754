import React from 'react';

import styles from './input.module.scss';

function Input({ label, value, onChange, autofocus = null }) {
  return (
    <div className={styles.wrapper}>
      {( label &&
        <label className={styles.label}>{label}</label>
      )}
      <input className={styles.input} value={value} onChange={event => onChange(event.target.value)} autoFocus={autofocus}/>
    </div>
  );
}

export default Input;
