import React from 'react';

import styles from './index.module.scss';

function Alert({ message, onClose = ()=>{} }) {
  return (
    <div className={styles.alert}>
      <div className={styles.message}>{message}</div>
      <div className={styles.close} onClick={onClose}>
        <i className="fas fa-times"/>
      </div>
    </div>
  );
}

export default Alert;
