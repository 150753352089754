import React from 'react';

import SpacesGridItem from './gridItem';
import styles from './grid.module.scss';

function SpacesGrid({ spaces }) {
  const items = spaces.map((space, index) => <SpacesGridItem key={index} space={space}/>);

  return (
    <div className={styles.spaces}>
      {items}
    </div>
  );
}

export default SpacesGrid;
