import React from 'react';
import ReactList from 'react-list';
import { DragDropContext } from "react-beautiful-dnd";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';

import UserWorkloadCalendarDay from './day';

import styles from './calendar.module.scss';

const UPDATE_TODO = gql`
  mutation UpdateTodo($id: ID!, $dueAt: ISO8601DateTime!) {
    updateTodo(id: $id, dueAt: $dueAt) {
      id
      name
      dueAt
    }
  }
`;


function onDragEnd(result, updateTodo) {
  const todoId = parseInt(result.draggableId, 10);
  const dueAt = result.destination && result.destination.droppableId;

  if (dueAt !== null) {
    updateTodo({
      variables: {
        id: todoId,
        dueAt
      }
    })
  }
}

function renderItem(user, index, key) {
  return <UserWorkloadCalendarDay key={index} index={index} user={user}/>;
}

function UserWorkloadCalendar({ user, client }) {
  const [updateTodo] = useMutation(UPDATE_TODO, {
    update(cache, { data: { updateTodo } }) {
      client.resetStore();
    }
  });

  return (
    <div className={styles.calendar}>
      <div className={styles.days}>
        <div style={{overflowX: 'auto', overflowY: "hidden"}}>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, updateTodo)}>
            <ReactList
              axis='x'
              itemRenderer={(index, key) => renderItem(user, index, key)}
              length={500}
              type='variable'
            />
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

export default withApollo(UserWorkloadCalendar);
