import React from 'react';

import styles from './fieldset.module.scss';

function Fieldset({ children }) {
  return (
    <fieldset className={styles.fieldset}>
      {children}
    </fieldset>
  );
}

export default Fieldset;
