import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Button from '../../components/button';
import ButtonBar from '../../components/button/bar';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import SpaceForm from '../../components/space/form';
import SpacesGrid from '../../components/spaces/grid';
import Layout from '../../components/layout';
import Title from '../../components/title';

const GET_SPACES = gql`
  query GetSpaces($archived: Boolean) {
    spaces(archived: $archived) {
      id
      name
      updatedAt
      archivedAt
      key
      progressInPercent
      client {
        id
        name
      }
    }
  }
`;

function Spaces({ match }) {
  const [archived, toggleArchived] = useState(false);
  const [showModal, toggleShowModal] = useState(false);

  const { loading, error, data } = useQuery(GET_SPACES, {
    variables: {
      archived: archived
    }
  });

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  const toggleButtonLabel = archived ? 'Hide archived' : 'Show archived';

  return (
    <>
      <Title title='Spaces'/>
      <Layout>
        {( showModal &&
          <Modal name='All right, let’s set up your new space!' onClose={event => toggleShowModal(false)}>
            <SpaceForm onSuccess={event => toggleShowModal(false)}/>
          </Modal>
        )}
        <ButtonBar>
          <Button label={toggleButtonLabel} onClick={event => toggleArchived(!archived)} variant='secondary'/>
          <Button label='Add Space' onClick={event => toggleShowModal(true)}/>
        </ButtonBar>
        <SpacesGrid spaces={data.spaces}/>
      </Layout>
    </>
  );
}

export default Spaces;
