import React, { useState } from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Alert from '../alert';
import Button from '../button';
import Fieldset from '../forms/fieldset';
import Input from '../forms/input';
import ClientsSelect from '../clients/select';
import SpacesSelect from '../spaces/select';
import styles from './form.module.scss';

const ADD_INVOICE = gql`
  mutation AddInvoice($name: String!, $spaceId: Int!, $clientId: Int!) {
    addInvoice(name: $name, spaceId: $spaceId, clientId: $clientId) {
      id
      number
      name
      state
      createdAt
      client {
        id
        name
      }
      space {
        id
        name
      }
    }
  }
`;

const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($id: ID!, $name: String!, $spaceId: Integer!, $clientId: Int!) {
    updateInvoice(id: $id, name: $name, spaceId: $spaceId, clientId: $clientId) {
      id
      number
      name
      state
      createdAt
      client {
        id
        name
      }
      space {
        id
        name
      }
    }
  }
`;

function InvoiceForm({ invoice, client, onSuccess }) {
  const id = invoice && invoice.id;
  const [name, setName] = useState(invoice ? invoice.name : '');
  const [clientId, setClientId] = useState(invoice ? parseInt(invoice.client.id, 10) : '');
  const [spaceId, setSpaceId] = useState(invoice ? parseInt(invoice.space.id, 10) : '');
  const [error, setError] = useState(null);
  const mutationQuery = invoice ? UPDATE_INVOICE : ADD_INVOICE;

  return (
    <Mutation
      mutation={mutationQuery}
      variables={{ id: id, name, clientId, spaceId }}
      update={(store, { data: { addInvoice } }) => {
        client.resetStore();
        onSuccess();
        setError(null);
      }}
      onError={({ message }) => {
        setError(message);
      }}
    >
      {(mutation, loading) => {
        return (
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
            }
          }>
            {( error &&
              <Fieldset>
                <Alert message={error} onClose={event => setError(null)}/>
              </Fieldset>
            )}
            <Fieldset>
              <Input label='Name' value={name} autofocus={true} onChange={setName}/>
            </Fieldset>
            <Fieldset>
              <SpacesSelect spaceId={spaceId} onChange={value => setSpaceId(value)}/>
            </Fieldset>
            <Fieldset>
              <ClientsSelect clientId={clientId} onChange={value => setClientId(value)}/>
            </Fieldset>
            <Fieldset>
              <Button
                label='Save'
                onClick={event => {
                  mutation();
                }}
              />
            </Fieldset>
          </form>
        )
      }}
    </Mutation>
  );
}

export default withApollo(InvoiceForm);
