import React from 'react';
import { NavLink } from 'react-router-dom';

import { pathTo } from '../../routes';

import styles from './card.module.scss';

function TodoCard({ todo }) {

  return (
    <div className={styles.card}>
      <div className={styles.space}>
        <NavLink to={pathTo(todo.space)}>
          {todo.space.key} - {todo.space.name}
        </NavLink>
      </div>
      <div className={styles.name}>
        <NavLink to={pathTo(todo)}>
          {todo.name}
        </NavLink>
      </div>
    </div>
  );
}

export default TodoCard;
