import React from 'react';

import styles from './checkbox.module.scss';

function Checkbox({ label, value, onChange }) {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>
        <input className={styles.checkbox} type='checkbox' checked={value} onChange={event => onChange(event.target.checked)}/> {label}
      </label>
    </div>
  );
}

export default Checkbox;
