import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';

import Button from '../button';
import { confirmDialog } from '../confirmation/dialog';
import { pathTo } from '../../routes';
import TimelogFormModal from '../timelog/formModal';
import TimerButton from '../timer/button';
import TimeDate from '../time/date';
import TimeInWords from '../time/inWords';
import UserInfo from '../user/info';
import styles from './listingItem.module.scss';

const DELETE_TIMELOG = gql`
  mutation DeleteTimelog($id: ID!) {
    deleteTimelog(id: $id) {
      id
    }
  }
`;

function TimelogsListingItem({ timelog, client }) {
  const [showFormModal, setShowFormModal] = useState(false);
  const [workloadInSeconds, setWorkloadInSeconds] = useState(timelog.workloadInSeconds);
  const [deleteTimelog] = useMutation(DELETE_TIMELOG, {
    update(cache, { data: { deleteTimelog } }) {
      client.resetStore();
    }
  });

  // keep inceasing the workload every 60 seconds by 50 seconds
  if (timelog.state === 'running') {
    setTimeout(function(){
      setWorkloadInSeconds(workloadInSeconds + 60);
    }, 60000);
  }

  return (
    <div className={styles.timelog} data-active={timelog.state === 'running'}>
      <div className={styles.user}>
        <UserInfo user={timelog.user}/>
        {( timelog.description &&
          <div className={styles.description}>{timelog.description}</div>
        )}
        {( timelog.todo &&
          <div className={styles.todo}>
            <NavLink to={pathTo(timelog.todo)}>{timelog.todo.number} - {timelog.todo.name}</NavLink>
          </div>
        )}
      </div>
      <div className={styles.workload}>
        <TimeInWords key={workloadInSeconds} seconds={workloadInSeconds}/>
      </div>
      <div className={styles.timestamp}>
        {( timelog.state === 'running' &&
          <div className={styles.timespan}>
            <TimeDate date={timelog.loggedAt} format='HH:mm'/>
          </div>
        )}
        {( timelog.state === 'logged' &&
          <div className={styles.timespan}>
            <TimeDate date={timelog.startedAt} format='HH:mm'/> - <TimeDate date={timelog.loggedAt} format='HH:mm'/>
          </div>
        )}
        <div className={styles.date}>
          <TimeDate date={timelog.loggedAt}/>
        </div>
      </div>
      <div className={styles.actions}>
        {( timelog.state === 'running' &&
          <TimerButton timelog={timelog} size="small"/>
        )}
        {( timelog.state === 'logged' &&
          <Button label='Edit' size='small' variant='link' onClick={event => setShowFormModal(true)}/>
        )}
        <Button
          label='Delete'
          size='small'
          variant='danger'
          onClick={() => {
            confirmDialog(() => {
              deleteTimelog({
                variables: {
                  id: timelog.id
                }
              })
            });
          }}/>
      </div>
      {( showFormModal &&
        <TimelogFormModal timelog={timelog} onToggle={setShowFormModal}/>
      )}
    </div>
  );
}

export default withApollo(TimelogsListingItem);
