import React, { useState } from 'react';
import { withApollo, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Alert from '../alert';
import Button from '../button';
import Fieldset from '../forms/fieldset';
import Input from '../forms/input';
import { secondsToTimeInWords, wordsToTimeInSeconds } from '../time/inWords';
import styles from './form.module.scss';

const ADD_TIMELOG = gql`
  mutation AddTimelog($todoId: Int!, $workloadInSeconds: Int!, $userId: Int!, $description: String) {
    addTimelog(todoId: $todoId, workloadInSeconds: $workloadInSeconds, userId: $userId, description: $description) {
      id
      workloadInSeconds
      description
    }
  }
`;

const UPDATE_TIMELOG = gql`
  mutation UpdateTimelog($id: ID!, $workloadInSeconds: Int!, $description: String) {
    updateTimelog(id: $id, workloadInSeconds: $workloadInSeconds, description: $description) {
      id
      workloadInSeconds
      description
    }
  }
`;

function TimelogForm({ timelog, todo, onSuccess, client }) {
  const [workload, setWorkload] = useState(secondsToTimeInWords(timelog && timelog.workloadInSeconds));
  // const [userId, setUserId] = useState(timelog ? timelog.user.id : 1);
  // TODO: fix that over here
  const userId = timelog ? timelog.user.id : 1;
  const [description, setDescription] = useState(timelog && timelog.description ? timelog.description : '');
  const [error, setError] = useState(null);

  const variables = timelog ? { id: timelog.id } : { todoId: parseInt(todo.id, 10) };
  const mutation = timelog ? UPDATE_TIMELOG : ADD_TIMELOG;

  return (
    <Mutation
      mutation={mutation}
      variables={{ ...variables, workloadInSeconds: wordsToTimeInSeconds(workload), userId, description }}
      update={(store, { data: { addTodo } }) => {
        client.resetStore();
        onSuccess();
        setError(null);
      }}
      onError={({ message }) => {
        setError(message);
      }}
    >
      {(mutation, loading) => {
        return (
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
            }
          }>
            {( error &&
              <Fieldset>
                <Alert message={error} onClose={event => setError(null)}/>
              </Fieldset>
            )}
            <Fieldset>
              <Input label='Amount' value={workload} autofocus={true} onChange={setWorkload}/>
            </Fieldset>
            <Fieldset>
              <Input label='Description' value={description} onChange={setDescription}/>
            </Fieldset>

            <Fieldset>
              <Button
                label='Save'
                onClick={event => {
                  mutation();
                }}
              />
            </Fieldset>
          </form>
        )
      }}
    </Mutation>
  );
}

export default withApollo(TimelogForm);
