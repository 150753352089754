import React from 'react';
import moment from 'moment';

function TimeDate({ date, format='ddd, MMM Do, YYYY' }) {
  const dateString = moment(date).format(format);

  return (
    <span>{dateString}</span>
  );
}

export default TimeDate;
