import React from 'react';

import TodosListingItem from './listingItem';
import styles from './listing.module.scss';

function TodosListingSmall({ todos }) {

  if (todos.length === 0) {
    return null;
  }

  const items = todos.map(todo => <TodosListingItem key={todo.id} todo={todo}/>);

  return (
    <div className={styles.todos}>
      {items}
    </div>
  );
}

export default TodosListingSmall;
