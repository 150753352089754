import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import ActionButtons from './buttons';
import InvoiceStatus from '../../components/invoice/status';
import Loading from '../../components/loading';
import Layout from '../../components/layout';
import Title from '../../components/title';

import styles from './index.module.scss';

const GET_INVOICE = gql`
  query GetInvoice($id: ID!){
    invoice(id: $id) {
      id
      number
      name
      state
      createdAt
      client {
        id
        name
      }
      space {
        id
        name
      }
    }
  }
`;

function Invoice({ match }) {
  const invoiceId = parseInt(match.params.id, 10);

  const { loading, error, data } = useQuery(GET_INVOICE, {
    variables: {
      id: invoiceId
    }
  });

  if (loading) return (
    <Layout>
      <Loading/>
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  const { invoice } = data;

  if (invoice === null) {
    return (
      <Layout>
        Invoice not found.
      </Layout>
    )
  }

  return (
    <>
      <Title title={`${invoice.number} - Invoice`}/>
      <Layout>
        <div className={styles.invoice}>
          <div className={styles.number}>
            <div>{invoice.number} - {invoice.name}</div>
            <InvoiceStatus status={invoice.state}/>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Actions</div>
            <div className={styles.info}>
              <ActionButtons invoiceId={invoice.id} status={invoice.state}/>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Client</div>
            <div className={styles.info}>{invoice.client.name}</div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Space</div>
            <div className={styles.info}>{invoice.space.name}</div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Invoice;
