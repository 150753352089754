import React from 'react';

import styles from './section.module.scss';

function PopupSection({ children }) {
  return (
    <div className={styles.section}>
      {children}
    </div>
  );
}

export default PopupSection;
