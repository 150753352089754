import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import Button from '../button';
import { confirmDialog } from '../confirmation/dialog';
import DateSelector from '../date/selector';
import EstimateSelector from './estimateSelector';
import Modal from '../../components/modal';
import { pathTo } from '../../routes';
import Popup from '../popup';
import PopupAction from '../popup/action';
import PopupSection from '../popup/section';
import TimelogFormModal from '../timelog/formModal';
import TimerButton from '../timer/button';
import TodoForm from './form';
import styles from './actions.module.scss';

const DELETE_TODO = gql`
  mutation DeleteTodo($id: ID!) {
    deleteTodo(id: $id) {
      id
      name
    }
  }
`;

function TodoActions({ todo, history, client }) {
  const [showModal, toggleShowModal] = useState(false);
  const [showSettingsPopup, setSettingsPopup] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [deleteTodo] = useMutation(DELETE_TODO, {
    update(cache, { data: { deleteTodo } }) {
      client.resetStore();
      history.push(pathTo(todo.space));
    }
  });

  return (
    <div className={styles.actions}>
      <div className={styles.action}>
        <DateSelector todo={todo} size='normal'/>
      </div>
      <div className={styles.action}>
        <EstimateSelector todo={todo}/>
      </div>
      <div className={styles.divider}/>
      <div className={styles.action}>
        <TimerButton todo={todo}/>
      </div>
      <div className={styles.action}>
        <Button label='Log time' onClick={() => setShowTimeModal(true)} variant='secondary'/>
      </div>
      <div className={styles.divider}/>
      <div className={styles.action}>
        <Popup show={showSettingsPopup} togglePopup={setSettingsPopup}>
          <Button variant="link" onClick={() => setSettingsPopup(true)}>
            <i className="fas fa-ellipsis-h"/>
          </Button>
          <>
            <PopupSection>
              <PopupAction
                onClick={() => {
                  toggleShowModal(true);
                  setSettingsPopup(false);
                }}
              >
                <i className="far fa-edit"/><span>Edit</span>
              </PopupAction>
            </PopupSection>
            <PopupSection>
              <PopupAction
                onClick={() => confirmDialog(() => {
                  deleteTodo({ variables: { id: parseInt(todo.id, 10) } });
                  setSettingsPopup(false);
                })}
              >
                <i className="far fa-trash-alt"/><span>Delete</span>
              </PopupAction>
            </PopupSection>
          </>
        </Popup>
      </div>
      {( showModal &&
        <Modal name={`Editing todo: ${todo.name}`} onClose={event => toggleShowModal(false)}>
          <TodoForm todo={todo} onSuccess={event => toggleShowModal(false)}/>
        </Modal>
      )}
      {( showTimeModal &&
        <TimelogFormModal todo={todo} onToggle={setShowTimeModal}/>
      )}
    </div>
  );
}

export default withApollo(withRouter(TodoActions));
